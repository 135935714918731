<template>
  <div class="modal-material">
    <b-modal id="modal-quiz" centered hide-footer @hidden="resetModal">
      <template #modal-title>
        <div class="modal-material__title">
          {{ isModalTypeCreated ? $t('header.testCreation') : $t('header.editQuiz') }}
        </div>
      </template>
      <template #modal-header-close>
        <SmallCloseButton />
      </template>
      <template #default>
        <div class="content" :class="{ 'h-100': questions.length }">
          <div class="content__info">
            <AppInput
              :value="title"
              :label="$t('placeholders.enterNameForTheTest')"
              name="title"
              type="text"
              @blur="setField"
            />
            <AppInput
              :value="description"
              :label="$t('placeholders.enterDescriptionForTheTest')"
              name="description"
              type="text"
              @blur="setField"
            />
          </div>
          <div class="content__attempt-block">
            <div class="attempt-block">
              <div class="attempt-block__item">
                <div class="attempt-block__left">
                  <div class="attempt-block__title">
                    <p>
                      {{$t('label.totalPoints')}} <span class="max-counter">{{ max_score }}</span>
                    </p>
                  </div>
                  <div class="attempt-block__subtitle">
                    <p>{{$t('label.quizScoreSum')}}</p>
                  </div>
                </div>
                <div class="attempt-block__right">
                  <div class="right-title">
                    <div class="attempt-block__title">
                      <p>{{$t('label.minPassingScore')}}</p>
                    </div>
                    <div class="attempt-block__subtitle">
                      <p>{{$t('label.quizScorePass')}}</p>
                    </div>
                  </div>
                  <div class="attempt-block__input min-input">
                    <input
                      ref="minScoreInput"
                      type="number"
                      class="simple-input"
                      :class="{
                        invalid: $v.passing_score.$dirty && !$v.passing_score.scoreOverflow,
                      }"
                      v-model.number="passing_score"
                      @keypress="isNumber($event)"
                      @change="setMinScore"
                    />
                    <div
                      class="error-message"
                      :class="{
                        'error-message--activate':
                          $v.passing_score.$dirty && !$v.passing_score.scoreOverflow,
                      }"
                    >
                      <p>{{$t('errorMessages.greaterThanMax')}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="attempt-block__item">
                <div class="attempt-block__left">
                  <div class="attempt-block__title">
                    <p>{{ $t('label.limitNumbersOfAttempts') }}</p>
                  </div>
                  <div class="attempt-block__subtitle">
                    <p>{{ $t('label.byDefaultAttemptsAreUnlimited') }}</p>
                  </div>
                </div>
                <div class="attempt-block__right">
                  <div class="attempt-block__switcher">
                    <LmsSwitch :value.sync="isEnabledAttempts" size="large" withIcon />
                  </div>
                  <div class="attempt-block__input">
                    <input
                      ref="input"
                      v-model.number="attempts"
                      type="number"
                      class="simple-input"
                      :class="{
                        'simple-input--disabled': !isEnabledAttempts,
                        invalid: $v.attempts.$dirty && !$v.attempts.maximumNotExceeded,
                      }"
                      :disabled="!isEnabledAttempts"
                      @keypress="isNumber($event)"
                      @change="setAttempts"
                    />
                  </div>
                </div>
                <div
                  class="error-message"
                  :class="{
                    'error-message--activate':
                      $v.attempts.$dirty && !$v.attempts.maximumNotExceeded,
                  }"
                >
                  <p>{{ $t('label.maximum') }} 999</p>
                </div>
              </div>
            </div>
          </div>
          <div class="content__questions-wrapper">
            <div
              class="content__questions"
              :class="{ 'content__questions--hidden': !questions.length }"
            >
              <ContentList
                class="questions-list"
                handle=".drag"
                :list="questions"
                type="question"
                :disableDragAndDrop="isDisabledDragAndDrop"
                @changeOrder="changeOrder"
                @remove="removeQuestion"
              />
            </div>
            <div
              class="content__action"
              ref="buttons"
              :class="{
                'justify-content-between': questions.length,
                'content__action--mb-5': questions.length,
              }"
            >
              <AddButtonWithIcon
                variant="secondary"
                :title="$t('buttonLabels.addAQuestion')"
                @click="addQuestion"
              />
              <AddButton
                v-if="questions.length"
                :title="$t('buttonLabels.addTestToCourse')"
                @click="$bvModal.hide('modal-quiz')"
              />
            </div>
          </div>
        </div>
        <transition name="component-fade" mode="out-in">
          <div key="loading" v-if="showLoader" class="container f-center mt-5">
            <Loader />
          </div>
          <div v-else-if="!questions.length" key="empty" class="empty-state">
            <div class="empty-state__image">
              <img src="@/assets/images/svg/computer.svg" alt="Компьютер" />
            </div>
            <div class="empty-state__title">{{ $t('supportText.createTests') }}</div>
          </div>
        </transition>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ContentList from '@/components/ContentList.vue';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import AddButtonWithIcon from '@/components/Buttons/AddButtonWithIcon.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import AppInput from '@/components/AppInput.vue';
import Loader from '@/components/Loader/Loader.vue';
import LmsSwitch from '@/components/LmsSwitch.vue';

export default {
  name: 'ModalQuiz',
  components: {
    ContentList,
    SmallCloseButton,
    AddButtonWithIcon,
    AddButton,
    AppInput,
    Loader,
    LmsSwitch,
  },
  data: () => ({
    isDisabledDragAndDrop: false,
    showLoader: true,
    isEnabledAttempts: false,
    attempts: 0,
    passing_score: 0,
  }),
  validations: {
    attempts: {
      maximumNotExceeded(int) {
        return int < 1000;
      },
    },
    passing_score: {
      scoreOverflow(int) {
        return int <= this.max_score;
      },
    },
  },
  computed: {
    ...mapGetters({ isModalTypeCreated: 'modalType/getIsModalTypeCreated' }),
    ...mapGetters({ currentQuiz: 'courseContentTeacher/selectedContent' }),
    ...mapGetters({ questions: 'quizTeacher/getQuestions' }),
    title: {
      get() {
        return this.currentQuiz?.name ?? '';
      },
      set(name) {
        if (this.title !== name && this.currentQuiz._id) {
          this.updateActivity({ id: this.currentQuiz._id, activity: { name } });
        }
      },
    },
    description: {
      get() {
        return this.currentQuiz?.description ?? '';
      },
      set(description) {
        if (this.description !== description && this.currentQuiz._id) {
          this.updateActivity({ id: this.currentQuiz._id, activity: { description } });
        }
      },
    },
    max_score() {
      let scores = 0;
      this.questions.forEach((element) => {
        scores += element.max_score;
      });
      return scores;
    },
  },
  methods: {
    ...mapActions('courseContentTeacher', ['updateActivity', 'selectContentId']),
    ...mapActions('modalType', ['setModalTypeIsCreated']),
    ...mapActions('quizTeacher', [
      'createQuestion',
      'fetchQuestions',
      'resetQuestions',
      'removeQuestion',
      'reorderQuestions',
    ]),
    setField(e) {
      this[e.target.name] = e.target.value;
    },

    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },

    async setAttempts(e) {
      if (+e.target.value < 0) {
        this.attempts = 0;
        this.$refs.input.blur();
        return;
      }
      this.$refs.input.blur();

      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      await this.updateActivity({
        id: this.currentQuiz._id,
        activity: {
          type: 'quiz',
          meta: {
            attempts: +e.target.value,
            passing_score: this.passing_score,
          },
        },
      });
    },
    async setMinScore(e) {
      if (+e.target.value < 0) {
        this.passing_score = 0;
        this.$refs.minScoreInput.blur();
        return;
      }
      if (e.target.value === '') {
        this.passing_score = 0;
        this.$refs.minScoreInput.blur();
        return;
      }
      this.$refs.minScoreInput.blur();

      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      await this.updateActivity({
        id: this.currentQuiz._id,
        activity: {
          type: 'quiz',
          meta: {
            attempts: this.attempts,
            passing_score: this.passing_score,
          },
        },
      });
    },
    addQuestion() {
      this.createQuestion(this.currentQuiz._id).then(async () => {
        await this.fetchQuestions(this.currentQuiz._id);
        this.$nextTick(() => {
          if (this.questions.length > 1) {
            this.$refs.buttons.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    },
    resetModal() {
      this.setModalTypeIsCreated(false);
      this.selectContentId(null);
      this.resetQuestions();
      this.title = '';
      this.description = '';
      this.showLoader = true;
      this.$v.$reset();
      this.$emit('unmountModal');
    },
    async changeOrder(newValue) {
      const oldOrder = this.questions.map((item) => item._id);
      const newOrder = newValue.map((item) => item._id);
      this.isDisabledDragAndDrop = true;
      await this.reorderQuestions({
        questionsByNewOrder: newValue,
        questionsByOldOrder: this.questions,
        payload: {
          id: this.currentQuiz._id,
          oldIds: oldOrder,
          newIds: newOrder,
        },
      });
      this.isDisabledDragAndDrop = false;
    },
  },
  watch: {
    currentQuiz: {
      handler(quiz) {
        if (quiz?.meta?.attempts) {
          this.attempts = quiz.meta.attempts;
          this.isEnabledAttempts = this.attempts >= 1;
        } else {
          this.attempts = 0;
        }
        if (quiz?.meta?.passing_score) {
          this.passing_score = quiz.meta.passing_score;
        } else {
          this.passing_score = 0;
        }
        this.isEnabledAttempts = this.attempts >= 1;
      },
      deep: true,
      immediate: true,
    },
    questions: {
      async handler() {
        if (this?.currentQuiz
        && this.passing_score !== this.currentQuiz?.meta?.passing_score
        && this.passing_score <= this.max_score) {
          await this.updateActivity({
            id: this.currentQuiz._id,
            activity: {
              type: 'quiz',
              meta: {
                attempts: this.attempts,
                passing_score: this.passing_score,
              },
            },
          });
        }
      },
      deep: true,
    },
    isEnabledAttempts(bool) {
      if (!bool && this.currentQuiz?._id) {
        this.updateActivity({
          id: this.currentQuiz._id,
          activity: {
            type: 'quiz',
            meta: {
              attempts: 0,
              passing_score: this.passing_score,
            },
          },
        });
      }
    },
    'currentQuiz._id': {
      async handler(id) {
        if (id && this.currentQuiz.type === 'quiz') {
          await this.fetchQuestions(id).catch(console.log);
          this.showLoader = false;
        }
      },
      immediate: true,
    },
  },
};
</script>
